<template>
    <div style="padding: 5px">
        <h3 class="mb-3" v-if="title">{{ title }}</h3>
        <div
            class="legendContainer"
            :style="{ 'grid-template-columns': gridCols }"
        >
            <div class="endpoint" v-for="ep in legend" :key="ep.name">
                <img
                    :src="ep.meta.icon"
                    width="25"
                    height="25"
                    class="ma-0 pa-0"
                    style="vertical-align: middle"
                />
                <a  
                    v-if="ep.meta.customData.endpoint"
                    class="ml-2 legendLink"
                    target="_blank"
                    :href="jiraLink(ep.meta.customData.endpoint.jira_key)"
                >
                    {{ ep.meta.customData.endpoint.hostname }}
                </a>
                <a
                    v-else-if="ep.meta.customData.person"
                    class="ml-2 legendLink"
                    target="_blank"
                    :href="personLink(ep.meta.customData.person.cu_uni)"
                >
                    {{ ep.meta.customData.person.name }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Legend",
    data() {
        return {
            numCols: 0,
            type: "",
            floorPlanId: 0,
            annotationId: 0,
            legend: null,
            title: "",
        };
    },
    computed: {
        gridCols() {
            let cols = "";
            for (let i = 0; i < this.numCols; i++) {
                cols += "150px ";
            }
            return cols;
        },
        
    },
    methods: {
        personLink(uni){
            return `https://search.sites.columbia.edu/pages/${uni}`
        }
    },
    async created() {
        this.numCols = this.$route.query.numCols;
        this.type = this.$route.query.type;
        this.floorPlanId = this.$route.query.floorPlanId;
        this.annotationId = this.$route.query.annotationId;
        this.title = this.$route.query.title.split("+").join(" ");
    },
    mounted() {
        if (localStorage.getItem("legend")) {
            this.legend = JSON.parse(localStorage.getItem("legend"));
        }
    },
    beforeDestroy() {
        localStorage.removeItem("legend");
    },
};
</script>
<style scoped>
.legendContainer {
    /* max-width: 950px; */
    display: grid;
    row-gap: 10px;
}
.legendLink {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}
.legendLink:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
